.jumbotron {
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 480px;
	position:relative;

	h1 {

		font-size: 84px;

		@media screen and (max-width: 480px) {
			font-size: 3em;
		}
	}


		.page-header__overlay {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			background-color: $primary-dark;
			opacity: 0.4;
		}

	&.page-header {
		min-height: 320px;
		position:relative;

		@media screen and (max-width: 480px) {
			display: none;
		}




	}

	&.page-header--mobile {
		display: none;

		@media screen and (max-width: 480px) {
			min-height: 180px;
			position:relative;
			display: block;
		}

		.page-header__overlay {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			background-color: $primary-dark;
			opacity: 0.4;
		}

		h1 {
			line-height: 1.6;

			@media screen and (max-width: 480px) {
				font-size: 2.6rem;
			}
		}

	}



	.underlined {
		position: relative;
		border-bottom: 4px solid rgba($med-grey, 0.4);
	}
}

.header-divider {
	position: relative;
	margin-top: -86px;

	path {
		fill: #fff;
	}
}