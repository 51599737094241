.carousel--controls {
	position: relative;

	.carousel--btn {
		position: absolute;
		width: 56px;
		height: 56px;
		border-radius: 56px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
		z-index: 999;
		top: 134px;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		outline: none;

		&:active {
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
		}
	}

	.btn--prev {
		left: -50px;
	}

	.btn--next {
		right: -50px;
	}
}
