header {
  height: 100px;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.24);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.24);
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.24);
  position: relative;
  z-index: 1001;
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;

  @media screen and(max-width: 480px) {
    height: 80px;
    box-shadow: none;
  }
  
}

.menu__btn--active {
  display: none !important;
  // position: relative;
  // bottom: -50px;
}

.menu__btn--inactive {
  display: inline-block !important;
  // position: relative;
  // bottom: -50px;
}

.menu__btn--hidden{
  display: none !important;
}

.menu__btn--visible{
  display: inline-block !important;
}

.nav__overlay {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f3f6f9;
  display: none;
  z-index: 1000;
  height: 100vh;
  overflow: scroll;

    &.nav__overlay--visible {
      display: block;
    }

    @media screen and (max-width: 768px) {
       position: absolute;
       overflow-y: scroll;
    }

  @media screen and(max-width: 480px) {
      position: absolute;
      overflow-y: scroll;
      top: 80px;
  }

}


.menu__list {
  list-style-image: url(https://img.icons8.com/metro/18/D61E58/sort-right.png);

  @media screen and(max-width: 768px) {
      max-height: 0;
      list-style-type: none;
      list-style-image: none;
      overflow: hidden;
      transition: all 0.2s ease-in-out;

      &.menu__list--open {
        max-height: 1000px;
      }
  }

  li {
     a {
      position: relative;
      color: $secondary;
      &:after {
        content: '';
        position: absolute;
        height: 3px;
        width: 0;
        transition: all 0.2s ease-in-out;
        background-color: $secondary;
        position: absolute;
        left: 0;
        bottom: -8px;

        @media screen and(max-width: 768px) {
            display: none;
        }

      }
      &:hover {
        text-decoration: none;


      &:after {
        content: '';
        position: absolute;
        width: 100%;
        transition: all 0.2s ease-in-out;
      }

      }
     }

    @media screen and(max-width: 768px) {
        border-bottom: 1px solid rgba($primary, 0.3);
    }


  }
}

.menu__list-trigger {
      @media screen and(max-width: 768px) {
        &:after {
          width: 100%;
          height: 1px;
          background-color: rgba($primary, 0.3);
        }
    }
}

.footer__list {
  list-style-image: url(https://img.icons8.com/metro/10/24375d/sort-right.png);
  }