.container {
	img {
		max-width: 100%;
		height: auto;
	}
}

.sponsor__image {
		transition: all 0.2s ease-in-out;
		border: 2px solid transparent;
	&:hover {
		border: 2px solid rgba($secondary, 0.2);
	}
}

.content-img {
	border-radius: 2px;
	box-shadow: 0 5px 15px 0 rgba(37,44,97,0.15), 0 2px 4px 0 rgba(93,100,148,0.2);
}

.img-icon-wrapper {
	width: 52px;
	height: 52px;
	border-radius: 52px;
	display: inline-block;
	position: relative;
	background-color: $secondary;

	img {
		margin-top: 8px;
		margin-left: 8px;
	}
}

// #patienten {
// 	.img-icon-wrapper {
// 		background-color: $secondary;
// 	}
// }

// #over-ons {
// 	.img-icon-wrapper {
// 		background-color: $primary-dark;
// 	}
// }