html, body {
	overflow-x: hidden;

	@media screen and (max-width: 480px) {
		max-width: 100vw;
	}
}

body {
	padding-top: 90px;

	&.no-scroll {
		position: relative;
		overflow: hidden;
	}
}

.alert {
	border-radius: 0;
}