ul {
	list-style-image: url(https://img.icons8.com/material/12/D61E58/filled-circle.png);

	li {
		font-size: 1.25rem;
	}
}

ol {
	li {
		font-size: 1.25rem;
	}
}

.breadcrumbs {
	background: $light-grey;
	border-radius: 4px;
	display: inline-block;
	li {
		a {
			font-size: 0.9rem;
			color: #000 !important;
			opacity: 0.5;
		}

		img {
			opacity: 0.4;
		}

		&.current, &:hover {
			a {
				opacity: 1;
			}

			img {
				opacity: 1;
			}

		}
	}
}